<template>
  <section class="card-warpper">
    <header class="card-header">
      <span class="title">{{ title }}</span>
    </header>
    <div class="card-content">
      <slot></slot>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    }
  }
};
</script>

<style lang="less">
.card-warpper {
  background-color: #fff;
  width: 100%;
  margin: 0 auto;
  margin-top: 40px;
  position: relative;
  border-radius: 3px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  color: #606266;

  .card-header {
    line-height: 2.8rem;
    text-align: left;
    padding: 0 20px;
    font-weight: 500;
    font-size: 1.3rem;
    background-color: #fbfbfb;
    border-bottom: 1px solid #efefef;
  }

  .card-content {
    padding: 20px 30px;
    text-align: left;
  }

  .card-btn {
    position: absolute;
    right: 20px;
    top: 6px;
  }
}
</style>
