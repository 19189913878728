<template>
  <section class="commodity-wrapper">
    <card title="商品类别管理">
      <el-button
        class="card-btn"
        size="small"
        type="primary"
        @click="editTag"
        >{{ closeFlag ? "完成" : "编辑" }}</el-button
      >
      <div class="tag-wrapper">
        <el-tag
          :key="tag.tid"
          v-for="tag in typeTags"
          :closable="closeFlag"
          :disable-transitions="false"
          @close="handleClose(tag)"
        >
          {{ tag.tname }}
        </el-tag>
        <el-input
          class="input-new-tag"
          v-if="inputVisible"
          v-model="inputValue"
          ref="saveTagInput"
          size="small"
          @keyup.enter.native="handleInputConfirm"
          @blur="handleInputConfirm"
        >
        </el-input>
        <el-button
          v-else-if="closeFlag"
          class="button-new-tag"
          size="small"
          @click="showInput"
          >增加类别</el-button
        >
      </div>
    </card>
    <card title="首页滚动介绍图管理">
      <el-upload
        :action="imgUploadUrl"
        list-type="picture-card"
        :on-success="handleSuccesspic"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :file-list="picList"
        ref="upload"
        :multiple="false"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <el-dialog :visible.sync="imgPreview">
        <img width="100%" :src="imgPreviewUrl" alt="" />
      </el-dialog>
    </card>
    <card title="商品管理">
      <el-button
        class="card-btn"
        size="small"
        type="primary"
        @click="addCommodity"
        >新增</el-button
      >
      <el-table :data="commodityList" border>
        <el-table-column
          label="商品名称"
          prop="pName"
          align="center"
        ></el-table-column>
        <el-table-column
          label="商品价格"
          prop="price"
          align="center"
          width="160"
          :formatter="priceFormatter"
        ></el-table-column>
        <el-table-column
          label="商品类别"
          prop="type"
          align="center"
          width="140"
          :formatter="typeFormatter"
        ></el-table-column>
        <el-table-column
          label="适用年龄段"
          align="center"
          :formatter="yearFormatter"
        ></el-table-column>
        <el-table-column label="操作" align="center" width="150">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="editCommodity(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="deleteCommodity(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="PageSize"
        layout="total, prev, pager, next,jumper"
        :total="totalCount"
        style="margin: 20px 0 0 30px"
      >
      </el-pagination>
    </card>
  </section>
</template>

<script>
import card from "../../components/card";

export default {
  components: {
    card
  },
  data() {
    return {
      inputVisible: false,
      inputValue: "",
      closeFlag: false,
      imgUploadUrl: "https://www.ymxjkx.com/api/back/product/uploadImage",
      imgPreviewUrl: "",
      imgPreview: false,
      picList: [],
      commodityList: [],
      // 默认显示第几页
      currentPage: 1,
      // 默认每页显示的条数（可修改）
      PageSize: 10,
      totalCount: 0
    };
  },
  computed: {
    typeTags: {
      get: function() {
        return this.$store.state.typelist;
      },
      set: function(value) {
        this.$store.state.typelist = value;
      }
    }
  },
  methods: {
    handleClose(tag) {
      this.$axios({
        url: "/back/product/deleteType",
        params: {
          id: this.typeTags.find(item => item.tid == tag.tid).tid
        }
      }).then(res => {
        if (res.data.code == 200) {
          this.$store.dispatch("getTypeList");
        } else {
          this.$message({
            type: "error",
            message: "删除失败"
          });
        }
      });
    },
    handleSuccesspic(res, file, fileList) {
      this.$axios({
        url: "/back/product/addMainImg",
        params: {
          imgUrl: res.data
        }
      }).then(res => {
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "轮播图上传成功"
          });
        } else {
          this.$message({
            type: "error",
            message: "添加轮播图失败"
          });
        }
      });
    },
    getMainImg() {
      this.$axios({
        url: "/back/product/getAllMainImg"
      }).then(res => {
        if (res.data.code == 200) {
          this.picList = res.data.data.map(item => {
            return { name: item.id, url: "https://" + item.imageUrl };
          });
        } else {
          this.$message({
            type: "error",
            message: "获取轮播图失败"
          });
        }
      });
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    getTag() {
      this.$axios({
        url: "/back/product/getAllType"
      }).then(res => {
        if (res.data.code == 200) {
          this.typeTags = res.data.data;
        } else {
          this.$message({
            type: "error",
            message: "错误"
          });
        }
      });
    },
    handleInputConfirm() {
      if (this.inputValue) {
        this.$axios({
          url: "/back/product/addType",
          params: {
            type: this.inputValue
          }
        }).then(res => {
          if (res.data.code == 200) {
            this.$store.dispatch("getTypeList");
          } else {
            this.$message({
              type: "error",
              message: "添加失败"
            });
          }
        });
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    editTag() {
      this.closeFlag = !this.closeFlag;
    },
    handleRemove(file, fileList) {
      this.$axios({
        url: "/back/product/deleteMainImg",
        params: {
          id: file.name
        }
      }).then(res => {
        if (res.data.code == 200) {
          this.$axios({
            url: "/back/product/deleteImage",
            params: {
              fileUrl: file.url
            }
          }).then(res => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "移除成功"
              });
            } else {
              this.$message({
                type: "error",
                message: "移除失败"
              });
            }
          });
        }
      });
    },
    handlePreview(file) {
      this.imgPreviewUrl = file.url;
      this.imgPreview = true;
    },
    editCommodity(index, row) {
      this.$store.commit("setProduct", row);
      this.$router.push({
        name: "editcommodity",
        query: { pid: row.pid }
      });
    },
    deleteCommodity(index, row) {
      this.$confirm("确认删除此商品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$axios({
          url: "/back/product/deleteProduct",
          params: {
            id: row.pid
          }
        }).then(res => {
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: "删除成功!"
            });
            this.getProduct();
          } else {
            this.$message({
              type: "error",
              message: "商品信息获取失败"
            });
          }
        });
      });
    },
    addCommodity() {
      this.$router.push({ name: "editcommodity" });
    },
    priceFormatter(row, col) {
      if (Number(row[col.property]))
        return "￥" + Number(row[col.property]).toFixed(2);
      else return "/";
    },
    typeFormatter(row, col) {
      return row.type.tname;
    },
    yearFormatter(row, col) {
      let upAge = this.ageConvert(row.upAge);
      let downAge = this.ageConvert(row.downAge);
      return downAge + "—" + upAge;
    },
    ageConvert(age) {
      if (age / 365 > 0 && age % 365 == 0) {
        return age / 365 + "年";
      } else if (age / 365 > 0 && age % 365 !== 0) {
        if (age % 30 == 0) {
          return age / 30 + "月";
        } else {
          return age + "天";
        }
      } else if (age >= 30 && age < 365) {
        if (age % 30 == 0) {
          return age / 30 + "月";
        } else {
          return age + "天";
        }
      } else {
        return age + "天";
      }
    },
    // 显示第几页
    handleCurrentChange(val) {
      // 改变默认的页数
      this.currentPage = val;
      this.getProduct();
    },
    getProduct() {
      this.$axios({
        url: "/back/product/getAllProduct",
        params: {
          page: this.currentPage
        }
      }).then(res => {
        if (res.data.code == 200) {
          this.PageSize = res.data.data.pageSize;
          this.totalCount = res.data.data.total;
          this.commodityList = res.data.data.list;
          setTimeout(this.$openLoading().close(), 1000);
        } else {
          this.$message({
            type: "error",
            message: "商品列表获取失败"
          });
        }
      });
    }
  },
  created() {
    this.$openLoading();
    this.getTag();
    this.getMainImg();
    this.getProduct();
  }
};
</script>

<style lang="less">
.commodity-wrapper {
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin: 10px auto;
    margin-left: 10px;
  }

  .tag-wrapper {
    min-height: 52px;
    span {
      margin: 10px auto;
    }
  }
}
</style>
